.comments-section {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  padding-top: 16px;

  border-radius: 10px;
  // backdrop-filter: blur(10px);
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  .comments-hor-container {
    overflow-y: auto;
    height: 250px;
    // padding: 0 10px;
    margin-top: 20px;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      // background: #888;
      border-radius: 3px;
    }

    .card-main-container {
      margin-top: 25px;
      width: 290px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}

.comment_list {
}

.shimmer_container {
  margin-top: 20px;
}
.text-overflow {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}

.no-comment {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  font-family: "AvenirPro-regular";
}

.no-testimonials {
  height: 82px !important;
  font-size: 14px;
  line-height: 17px;
}
.btn-see-more {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.wip {
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.section_test_text {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
