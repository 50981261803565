  
  .container-wrapper-no-search {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    position: relative;
    img{
      position: absolute;
      top:110px;
      width: 156.328px;
height: 169px;

    }
    .no-ratings-text {
      font-family: "AvenirPro-regular";
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: #000000cc;
      position: absolute;
      top:310px

      //margin-top: 120px;
    }
  }
  
 
