.comments-card-wrapper {
  padding: 8px 0;
  flex-direction: row;
  overflow-wrap: anywhere;
  .img-cont {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    // flex-basis: 15%;

    img {
      background-position: center;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      object-fit: cover;
      pointer-events: none;
    }
  }

  .text-wrapper {
    padding-left: 8px;
    // flex-basis: 80%;
    overflow: hidden;
  }
  .username {
    color: #000000;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .comment-text {
    color: #2d2d2d;
    font-family: "AvenirPro-regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }


  .comment-text-in {
    color: #2d2d2d;
    font-family: "AvenirPro-regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    align-self: end;
    white-space: break-spaces;
  }
  .username-wrapper {
    max-width: 200px; /* Set the width of the container */
    overflow: hidden; /* Hide any overflowing content */
  }
}


.NoDPURL {
  border-radius: 50%;
  border: 1px solid white;
  background-color: #fff;
}

.bg-image-no{
  background-color: #f2f2f2;
}
.reply_date_cont {
  padding-left: 60px;
}
.text-date {
  font-family: "AvenirPro-regular";
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #00000099;
}

.comment-line {
  width: 40px;
  height: 1px;
  margin-left: 50px;
  margin-right: 10px;
}

.view-replies-container {
  padding-left: 50px;
}

.view-more-replies {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #00000099 !important;
}

.text_view_more {
  cursor: pointer !important;
  font-family: "AvenirPro-regular" !important;

}

@media (max-width: 330px) {
  .comment-text {
    height: auto; /* Allowing the height to adjust based on content */
  }
}

.line_horizontal{
  width: 40px;
  margin-left: 50px;
  margin-right: 10px;

}

@media (max-width: 300px) {
  .username {
    max-width: 120px !important;
  }
  .comment-text {
    /* Allowing the container to take up full width */
    height: auto; /* Allowing the height to adjust based on content */
    /* Other mobile styles */
  }
}

.button_profile {
  cursor: pointer;
}
.button_default_profile{
  align-items: center;
  background-position: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
  background-color: #f2f2f2;
  justify-content: center;
  display: flex;
  img{
    background-position: center;
    height: auto !important;
    width: 30px !important;
    border-radius: 50%;
    object-fit: cover;
    pointer-events: none;
  }
}
.btick_comments {   
   margin-left: 5px;

  img {

    width: 12px;
    height: auto;
    pointer-events: none;
  }
}