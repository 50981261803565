
.header-needs {
  display: flex;
  align-items: end;
  color: #000;
  position: sticky;

  top: 0;
  background: white;
  z-index: 2;
  .back-icon {
    cursor: pointer;
    padding: 15px 23px 15px 29px;
  }

  .txt{
    display: flex;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
  }
  .spinner-padding{
    // margin-right: 12px;
    margin: 15px 15px 15px 29px;
  }
}
.needs_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20%;
  //margin-bottom: 40px;
}

.needs_card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  //align-items: center;
  // background-color: #f0f0f0;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 12px;
  min-height: 241px;
  min-width: 160px;
  padding: 10px;

 
}

/* Add specific styles for odd and even cards to create the staggered effect */
.needs_card.odd {
  background-color: #e0e0e0;
  position: relative;
  top: 25%;
  background-position: 50% 100%;
  border-radius: 12px;
  //margin-bottom: 20px;
}

.needs_card.even {
  background-color: #d0d0d0;
  position: relative;
  top: 0;
  background-position: 50% 100%;
  border-radius: 12px;
 // margin-bottom: 20px;
}

.loader-margin{
  margin-top: 10px;
  margin-bottom: 20px;
}
.leads-view-main-wrapper{
  height: 100vh ;
}


.leads-view1 {
  color: #000;
  overflow-x: hidden;
 
  //height: 100vh;
//  height: 100vh;
.oddcontainer1{
  padding-bottom: 10px;
}
.evenContainer1{
  padding-bottom: 60px; 
}

  .leads-div-container-1 {
   
    position: relative;
   // padding-bottom: 60px;
    width: 100%;
  //  height: 100%;
  height: 100vh ;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-x: hidden;
  .leads-div-1 {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    grid-column-gap: 15px;

margin-top: 20px;
    justify-items: center;
    text-align: left;


    .dynamic-div-1 {
      background-position: 50% 50%;
      background-size: cover;
      cursor: pointer;
      padding: 15px 12px 12px 12px;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      border-radius: 12px;
      height: 241px;
      min-width: 160px;
      overflow: hidden;
   
    }
      
     .even-div {
       position: relative;
      top: 20%;
     
    }
  }





  }

 

  .title-card {
    // padding: 12px;
    //font-size: 18px;
  //  font-weight: 500;
   line-height: 23px;
    display: flex;
    /* align-items: end; */
    /* justify-content: flex-end; */
    height: 100%;
    justify-content: end;
    flex-direction: column;
    // word-break: break-word;
    .locate {
      display: flex;
      align-items: baseline;
      padding-bottom: 6px;
      width: 130px;
      .name-place {
       
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-left: 5px;
       
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .shimmer-location1{
        height: 10px;
        width:10px
      }
    }
  }
  
  .Description-leads{
    width: 138px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    //display: block;
    overflow-wrap: anywhere;
    display: flex;
    max-width: 100%;
       height: fit-content;
       padding-bottom: 8px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    // padding-top: 5px;
    display: -webkit-box;
  

    .title-Description-leads-view {
      width: 138px;
      font-size: 18px;
      font-weight: 500;
      line-height: 21.6px;
      text-align: left;
      overflow-wrap: anywhere !important;
      /* display: flex; */
      max-width: 100%;
      height: -moz-fit-content;
      height: fit-content;
      // padding-bottom: 2px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      /* align-items: center; */
      /* padding-top: 5px; */
      display: -webkit-box;
      font-family: "AvenirPro-regular";
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      white-space: pre-wrap;       /* Since CSS 2.1 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      white-space: -pre-wrap;      /* Opera 4-6 */
      white-space: -o-pre-wrap;    /* Opera 7 */
      word-wrap: break-word;       /* Internet Explorer 5.5+ */
      a{
        color: #fff;
        font-style: italic;
      }
    }


   // max-height: 60px;
    //padding-bottom: 10px;
  }

  // .even-div:last-child {
 
  // }
}


.show-img-loader {
  display: block !important;

}
.hide-img-loader {
  display: none !important;
}

.back-display-Flex{
  display: flex;
}


.shimmer-location1{
  height: 8px;
  width:8px
}