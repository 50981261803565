.comments-body {
  padding: 10px 20px 0px 20px;
  // overflow-y: auto;
  // background-color: aqua;
  overflow-y: auto;
  height: calc(100vh - 55px);
}

.no-comments {
  height: calc(100vh - 55px);
  display: flex;
  align-items: center;
  justify-content: center;
}
