@font-face {
  font-family: "WorkSans-Regular";
  src: url("../../assets/fonts/fonts/WorkSans-Regular.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Raleway-Bold";
  src: url("../../assets/fonts/fonts/Raleway-Bold.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("../../assets/fonts/fonts/Raleway-Regular.ttf") format("opentype");
  /* You may include additional font properties if needed */
}

.popup-filter-bg {
  filter: brightness(40%);
  animation: add-filter 600ms;
}
@keyframes add-filter {
  from {
    filter: brightness(100%);
  }
  to {
    filter: brightness(40%);
  }
}

.test {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  height: 100vh;
  border: none !important;
  position: relative;

  .check {
    display: flex;
    justify-content: space-between;
    height: 44px;
    padding: 0 20px;
    /* margin: 20px 20px 0 20px; */
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;

    .user-title {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      // font-family: "WorkSans-Regular";

      white-space: nowrap;
      overflow: hidden;
      max-width: 320px;
      text-overflow: ellipsis;
    }
    .close-button-div {
      text-align: right;
      cursor: pointer;

      img {
        pointer-events: none;
      }
    }
    .close-button-loader {
      margin-bottom: 5px;
      margin-top: 3px;
    }
  }

  .card-wrapper {
    color: #000;
    padding: 0 20px;
    position: relative;
    border: 1px solid white;

    // .user-title-2{
    //   color: #000;
    // }

    .main-card {
      color: #000;
      /* height: 79.5vh; */
      padding: 15px 8px;
      border-radius: 8px;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      height: 638px;

      .top-sharing {
        margin-right: 10px;
        display: flex;
        /* text-align: right; */
        align-items: center;
        justify-content: flex-end;
        .share-icon {
          backdrop-filter: blur(0px);
          width: 20px;
          height: 20px;
          border-radius: 6px;
          background: rgba(255, 255, 255, 0.2196078431);
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-right: 5px;
          cursor: pointer;

          img {
            width: 12px;
            height: auto;
            pointer-events: none;
          }
        }
        .title {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          cursor: pointer;
          padding-left: 5px;
          backdrop-filter: blur(0px);
        }
      }

      .profile-image {
        backdrop-filter: blur(0px);
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        margin-bottom: 25px;
        background-color: white;
        border-radius: 50%;
        width: 128px;
        height: 128px;
        img {
          margin: auto;
          width: 124px;
          height: 124px;
          border-radius: 50%;
          object-fit: cover;
          pointer-events: none;
        }
      }

      .name-div {
        // backdrop-filter: blur(0px);

        .title-name {
          font-size: 30px;
          line-height: 36px;
          font-weight: 700;
          color: #ffffff;
          text-align: center;
          letter-spacing: 1px;
          // font-family: "Raleway-Bold";

          white-space: nowrap;
          overflow: hidden;
          max-width: 350px;
          letter-spacing: -0.5px;
          &::first-letter {
            text-transform: capitalize !important;
          }
        }
        .sub-name {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          margin-bottom: 30px;
          letter-spacing: 0.3px;
          // font-family: "Raleway-Regular";
          margin-top: 2px;
        }
      }

      .designations {
        // backdrop-filter: blur(0px);
        text-align: center;
        .title-designation {
          // font-family: "Raleway-Regular";
          font-weight: 600;
          letter-spacing: 0.5px;
        }
        .address {
          // font-family: "Raleway-Regular";
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.3px;
          margin-top: 5px;
        }
        margin-bottom: 40px;
      }

      .card-branding {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 92%;
        bottom: 0;

        .logo-elred {
          img {
            width: 70px;
            pointer-events: none;
          }
        }

        .main-card-profile {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          cursor: pointer;
          .profile-div {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background: #ffffff33;
            margin-right: 5px;
            cursor: pointer;

            img {
              pointer-events: none;
            }
          }

          .profile_txt {
            cursor: pointer;
          }
        }
      }
    }
  }
}

#canvas {
  background-color: white !important;
  width: 100%;
  overflow-y: auto;
}

.offcanvas-backdrop.show {
  // opacity: 0 !important;
}

.bottomPop {
  // border-radius: 16px 16px 0 0;
  border: none !important;
  // padding: 20px 25px;
  overflow-y: auto;

  height: 100vh !important;
  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 25px;
    background: rgb(255, 255, 255);
    padding: 21px 20px 15px 20px;
    position: sticky;
    top: 0;
    height: 44px;
    .title {
      color: black;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
    }
  }

  .superskill-title {
    color: #000;
    color: black;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.ratingsPop {
  border-radius: 16px 16px 0 0;
  border: none !important;
  // padding: 20px 25px;
  overflow-y: auto;

  height: 97vh !important;
  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      color: black;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
    }
  }

  .superskill-title {
    color: #000;
    color: black;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.bottomPopSmall {
  border-radius: 16px 16px 0 0;
  border: none !important;
  padding: 20px 25px;
  // min-height: 200px;
  overflow-y: auto;
  height: auto !important;
  position: relative;

  background: rgba(0, 0, 0, 0) !important;
  height: 100vh !important;
  border-radius: 0;
  padding: 0;

  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      color: black;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }

    .close-btn {
      cursor: pointer;
    }
  }
}

.bottomPopSmallShareCard {
  border-radius: 16px 16px 0 0;
  border: none !important;
  padding: 20px 25px;
  // min-height: 200px;
  overflow-y: auto;
  height: auto !important;
  position: relative;

  // background: rgba(0, 0, 0, 0.3) !important;
  background: transparent !important;
  // height: 100vh !important;
  border-radius: 0;
  padding: 0;

  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      color: black;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
    }
  }
}

.outer-div {
  background: white;
  padding: 20px 24px;
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 12px 12px 0 0;
}

.mid_part_skill {
  padding: 20px;
  padding-top: 0;
}

@media (min-width: 451px) {
  .bottomPop,
  .bottomPopSmall {
    width: 375px;

    // top: 50% !important;
    bottom: 0;
    left: 50% !important;
    // transform: translate(-50%, -50%) !important;
    transform: translateX(-50%) !important;
    // box-shadow: 0px -4px 13px 1px #0000001a; bottom backdrop
    position: relative;

    // background: rgba(0,0,0,0.3);
    // height: 100vh !important;
    // border-radius: 0;
    // padding: 0;

    // to hide the scrollbar
    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .bottomPopSmallShareCard {
    width: 375px;
    bottom: 0 !important;
    height: 100vh !important;
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0px -4px 13px 1px rgba(0, 0, 0, 0.1019607843);
  }

  #canvas {
    max-width: 376px;
    width: 100%;
    overflow-y:auto;
  }

  .ratingsPop {
    max-width: 376px !important;
    width: 100%;
    margin: 0 auto;
  }
}

.mini-badges {
  margin-top: 5px;
  .badges {
    .badge {
      backdrop-filter: blur(0px);
      width: 4.5px;
      height: 4.5px;
      margin: 0 1px;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        width: 3.5px !important;
        height: 3.5px !important;
        margin: 0 !important;
      }
    }

    .total-badge {
      font-size: 2px !important ;
    }
  }
}
.loader-close {
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.bg-filter-card-data-container {
  margin: -15px -8px;
  padding: 15px 8px;
  height: 100%;
  position: absolute;
  width: 100%;
  backdrop-filter: url(#filter);
}

.verified-user-card-icon-check {
  height: 28px;
  width: 28px;
  position: absolute;
  top: 157px;
  left: 194px;
  pointer-events: none;
}

@media only screen and (max-width: 450px) {
  .verified-user-card-icon-check {
    height: 28px;
    width: 28px;
    position: absolute;
    top: 157px;
    left: 57.5%;
    pointer-events: none;
  }
  .designations {
    margin-bottom: 30px;
  }
  .test .card-wrapper {
    color: #000;
    padding: 0 20px;
    position: relative;
    border: 1px solid white;
    // overflow: scroll;
    // height: 81vh;
  }
}
@media only screen and (max-width: 360px) {
  .test .card-wrapper .main-card .designations {
    margin-bottom: 20px;
  }
}

.email-address-wrapper{
  margin-bottom: 36px;
}


.card-parent-div{
  // background: yellow;
  // height: 100vh;
}