.sharecard-shimmer {
  color: #000;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  .title {
    color: #000;
    text-align: left;
    padding: 0 20px;
    margin-bottom: 10px;
    margin-top: -20px;
  }

  .card_shimmer {
    display: flex;
    align-items: center;
    justify-content: center;
    .card_wrapper {
      width: 90%;
      height: 90vh;
      background-color: #e7e9ef;
      border-radius: 10px;
      position: relative;

      .middle_section {
        height: 80vh;
        // background: red;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle_icons {
          display: flex;
          gap: 10px;
          margin-top: 20px;
        }
        .square_icons {
          display: flex;
          gap: 10px;
          margin-top: 20px;
        }
      }

      .bottom_title {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        bottom: 15px;
        align-items: center;
      }
    }
  }
}
