.signup_header{
    display: flex;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 1;
    img{
        cursor: pointer;
        padding: 15px 23px 15px 29px;
    }
    .title{
        color: #000;
        font-weight: 500;
        align-self: center;
    }
  }
  .show-img-loader-signup{
    display: block !important;
    margin: 5px 0 0 0px;
  }
.signup_page {
  margin: 0 20px;
  margin-top: 75px;

  .desc{
    color: #000;
    margin-bottom: 20px;
    font-weight: 500;
    font-family: "AvenirNextLTPro-Medium";
  }
  .socialInputs{
    display: block;
    padding-bottom: 20px;
  }
  .inputField {
    color: #000;
    margin-bottom: 20px;

    .label {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      //   font-family: 'AvenirNextLTPro-Regular';
    }
    .show-img-loader-logo {
      display: block !important;
      color: #848484;
      margin-left: auto;
      margin-right: 15px;
      margin-top: 0;
      position: relative;
      bottom: 34px;
    }
    .input_field_wrapper {
      position: relative;
      input {
        margin-top: 10px;
        color: #000;
        border-radius: 8px;
        background-color: #f7f7f7;
        outline: none;
        border: none;
        width: 100%;
        padding: 14px 20px;
        padding-right: 40px;
        font-size: 16px;
        font-weight: 500;

        &::placeholder{
          color: #848484;
        }
      }
      img {
        position: absolute;
        top: 45%;
        right: 0%;
      }
    }
  }

  .inputField-disabled {
    color: #000;
    margin-bottom: 20px;

    .label-disabled {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      //   font-family: 'AvenirNextLTPro-Regular';
    }

    .input_field_wrapper-disabled {
      position: relative;
      input {
        margin-top: 10px;
        color: #000;
        border-radius: 8px;
        background-color: #f7f7f7;
        outline: none;
        border: none;
        width: 100%;
        padding: 14px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }
      img {
        position: absolute;
        top: 45%;
        right: 0%;
      }
    }
  }

  .signup_radio {
    .question_txt {
      color: #000;
      margin-bottom: 16px;
    }
    input[type=radio]#whatsapp, input[type=radio]#text, input[type=radio]#none {
      accent-color: #E72D38;
    }
    .answer_txt {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      .title_radio {
        color: #000;
        margin-top: -5px;
        cursor: pointer;
      }
      span {
        margin-left: 8px;
      }
      .radio_img {
        cursor: pointer;
        img {
          margin-top: -10px;
          pointer-events: none;
        }
      }

      input[type="radio"] {
        border: 0.15em solid red !important;
        width: 20px !important;
        height: 20px !important;
        cursor: pointer;
      }
      input[type="radio"]:checked {
        background-color: red !important;
      }
    }
  }
}

.customSubmit{
    // background-color: yellow;
    padding-bottom: 25px;
    margin-top: 45px;
    // position: fixed !important;
    .buttonText{
        color: white;
        background-color: #E72D38;
        padding: 17px 0;
        text-align: center;
        border-radius: 100px;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        cursor: pointer;
    }
    .disabled{
      color: white;
      background-color: #E72D3880;
      padding: 17px 0;
      text-align: center;
      border-radius: 100px;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
    }
}

.phone-label {
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.phone-field {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .phone-country-code {
    max-width: 74px;
  }

  .country-code-with-error {
    margin-bottom: 15px;
  }

  .phone-number-field {
    width: 348px;
  }
}
.MB-20{
  margin-bottom: 20px;
}


// @media (min-width:451px){
//   .customSubmit{
//     max-width: 375px !important;
//     width: 100%;
//   }
// }