.awardview {
  background-color: white;
  height: 100vh;
  color: #000;

  .award-header {
    color: #000;
    /* padding: 20px; */
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    background-color: white;
    position: sticky;
    top: 0;

    .award-back {
      cursor: pointer;
      padding: 15px 23px 15px 29px;
    }

    .back-icon-awar-spinner {
      margin-right: -8px;
    }

    .title {
      // font-family: "WorkSans-Regular";
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      // margin-left: 16px;
    }
  }

  .award-title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    // text-align: center;
    word-wrap: break-word;
    margin: 0 20px;
    // text-transform: capitalize;
    text-align: center;
  }

  .awardImg-div {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;

    img {
      height: 118px;
      width: auto;
      pointer-events: none;
    }
  }

  .body-key {
    color: #000;
    margin: 0 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    // margin-bottom: 25px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;

    .body-text {
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 20px;
      color: #313030;
    }

    img {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      margin-top: -4px;
    }

    .key_name {
      color: #000;
      min-width: 90px;
      max-width: 90px;
      width: 100%;
      // background: red;
    }

    .key_value {
      color: #313030;
      min-width: 50%;
      // background: pink;
      word-wrap: break-word;
      font-size: 16px;
      font-weight: 100;
      font-family: "AvenirNextLTPro-Regular";
      font-weight: 400;
      line-height: 19.2px;
    }

    .colon {
      color: #000;
      min-width: 15px;
      // background: yellow;
    }
  }
}

.preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 35px; */
  margin: 0 20px;
  padding-bottom: 30px;
 
  .overlayCert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    z-index: 1;
    border-radius: 4px 4px 0 0;
  }
  .certificateImage{
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 4px;
  }
  .show-img-loader-certificate{
    display: block;
    margin: 50px auto;
  }
  .preview-awards {
    // width: 335px;
    width: 100%;
    height: 185px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    .label-certificate {
      background-color: white;
      color: black;
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid #dfdfdf;
      border-left: 1px solid #dfdfdf;
      border-right: 1px solid #dfdfdf;
      border-radius: 0 0 4px 4px;
      padding: 15px;
      display: flex;
      img{
        margin-right: 10px;
        pointer-events: none;
      }
      .pdf-spinner{
        margin-right: 12px;
        margin-top: 2px;
      }
      .name {
        color: #000;
        // margin-left: 10px;
      }
    }

    .loader_div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 1;
      border-radius: 4px;
    }
  }
}