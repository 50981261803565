.certificate_shimmer {
  background-color: #e1e4eb;
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    max-width: 254px;
    width: 100%;
  }
}
