.needs {
  margin-top: 15px;
  border-radius: 10px;

  .needs-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    .heading {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }

    .see-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-family: "AvenirPro-regular";
      cursor: pointer;
    }
  }

  .needs-card-div:last-child {
    padding-right: 15px;
  }

  .needs-card-div {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px 15px;
    overflow: auto;
    padding-top: 0;
    gap: 8px;
    padding-bottom: 13px;
    .needs-card {
      cursor: pointer;
      min-width: 280px;
      max-width: 280px;
      min-height: 130px;
      border: 1px solid #ffffff80;
      border-radius: 20px;
      padding: 15px 20px;
      // margin-right: 8px;

      .date {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 10px;
        font-weight: 400;
        letter-spacing: -0.4px;
        font-family: "AvenirPro-regular";
      }

      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: -0.6px;
        word-break: break-word;
        // text-transform: lowercase;
        // white-space: break-spaces;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 3em;
        white-space: pre-line;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.word_count {
  color: #929292;
  padding: 0 20px;
  text-align: right;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
}

.name_word_count {
  color: #929292;
  text-align: right;
  margin-top: -15px;
  font-size: 12px;
}

#style_toast_container__DT-ei {
  .style_toast-message__-UN8x {
    width: 75% !important;
    text-align: center;

    .style_toast-content__jaj36 {
      font-size: 12px !important;
      background-color: black !important;
      padding: 10px 20px !important;
    }
  }
}

@media (min-width: 451px) {
  .word_count {
    width: 375px;
  }

  #style_toast_container__DT-ei {
    .style_toast-message__-UN8x {
      max-width: 375px;
      width: 75%;
      text-align: center;

      .style_toast-content__jaj36 {
        width: 75%;
      }
    }
  }
}