.session_expired {
  color: #000;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;

  .center_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 225px;
      width: 100%;
    }
    .title {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      font-family: "Poppins-bold";
    }
    .desc {
      margin-top: 5px;
      color: #979797;
      font-size: 14px;
      font-weight: 400;
    }
    .retry {
      margin-top: 30px;
      background-color: #e72d38;
      padding: 17px 55px;
      border-radius: 100px;
      width: 160px;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
