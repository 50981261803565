@font-face {
  font-family: "Nunito-Light";
  src: url("../../../assets/fonts/fonts/Nunito-Light.ttf") format("opentype");
  /* You may include additional font properties if needed */
}

.skillcard {
  margin-top: 11px;
  color: black;
  margin-bottom: 20px;

  .heading {
    // font-family: "Nunito-Light";

    max-height: 110px;
    // background-color: rgb(109, 109, 109);
    // background: linear-gradient(90deg, #e72d38 4.18%, #000000 190.3%);
    background-size: cover;
    border-radius: 14px 14px 0 0;
    // max-width: 335px;
    padding: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    display: flex;
    align-items: center;
  }
  .description {
    min-height: 120px;
    background-color: white;
    border: 1px solid #e3e3e3;
    box-shadow: 0px 4px 10px 0px #0000001a;
    font-weight: 500;
    font-size: 18px;

    color: black;
    border-radius: 0 0 14px 14px;
    padding: 15px 20px;
    line-height: 36px;
    // max-width: 335px;

    .no-answer-added {
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background: red; */
      height: 10vh;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .text-description {
    color: black;
    overflow: hidden;
    display: -webkit-box;
    // -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 335px;
    // word-break: break-all;
    white-space: break-spaces;
    line-height: 30px;
    word-wrap: break-word;
    a {
      font-style: italic;
    }
  }
}
