  
  .container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .no-ratings {
    font-family: "AvenirPro-regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000cc;
  }

  .no-Awards{
    width: 266px;
height: 212px;


  }
  