
.left-container,
.right-container {
    width: 30%;
    height: 58vh;
    background-color: transparent;
    z-index: 999;
    cursor: pointer;
    position: absolute;
}

.left-container {
    left: 0;
}

.right-container {
    right: 0;
}


@media only screen and (max-width: 450px) {
    .left-container,
    .right-container {
        width: 30%;
        height: 58vh;
        background-color: transparent;
        z-index: 999;
        cursor: pointer;
        position: absolute;
    }
}