.share-card {
  background: rgb(255, 255, 255);
  height: 100vh;
  width: 100%;
  color: #000;

  .username {
    color: #000;
    font-size: 16px;
    // font-family: "WorkSans-Regular";
    font-weight: 400;
    // font-weight: 600;
    line-height: 20px;
    padding: 12px 20px;
  }

  .card-div {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;

    .card-container {
      margin: 0 20px;
      border-radius: 10px;
      padding: 10px;
      width: 100%;
      background-size: cover !important;
      position: relative;
      height: 638px;

      .user-image {
        text-align: center;
        margin: 35px auto 0 auto;
        -webkit-backdrop-filter: blur(0);
        backdrop-filter: blur(0);
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 128px;
        width: 128px;

        img {
          width: 124px;
          height: 124px;
          border-radius: 50%;
          padding: 0 !important;
          object-fit: cover;
          pointer-events: none;
          margin: auto
        }
      }

      .user-name {
        color: white;
        font-size: 28px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 0.3px;
        margin-top: 20px;
        // font-family: "Raleway-Bold";
      }

      .user-surname {
        color: white;
        font-size: 18px;
        font-weight: 600;
        // margin-top: 5px;
        text-align: center;
        letter-spacing: 0.3px;
        // white-space: nowrap;
        // overflow: hidden;
        width: 285px;
        // text-overflow: ellipsis;
        margin: 0 auto;
        // font-family: "Raleway-Regular";
        line-height: 20px;
      }

      .professions {
        font-size: 16px;
        // font-family: "Raleway-Regular";
        font-weight: 600;
        letter-spacing: 0.5px;
        // line-height: 20px;
        margin-top: 25px;
        text-align: center;
      }

      .locations {
        font-size: 16px;
        // font-family: "Raleway-Regular";
        font-weight: 500 !important;
        text-align: center;
      }

      .badges-div {
        margin-top: 40px;
        backdrop-filter: blur(0px);
      }
    }
  }
}


.verified-user-share-card-icon {
  height: 28px;
  width: 28px;
  position: absolute;
  top: 170px;
  left: 195px;
  pointer-events: none;
}

@media only screen and (max-width: 450px) {
  .verified-user-share-card-icon {
    height: 28px;
    width: 28px;
    position: absolute;
    top: 169px;
    left: 57.5%;
    pointer-events: none;
  }
}

.card-branding-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 92%;
  bottom: 0;

  .logo-elred {
    img {
      width: 70px;
    }
  }

  .main-card-profile {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    .profile-div {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.2);
      // margin-right: 5px;
      cursor: pointer;
    }

    .shareTxt {
      cursor: pointer;
      padding-left: 5px;
    }
  }
}

.bg-filter-sharecard {
  backdrop-filter: url(#filter);
  margin: -10px;
  padding: 10px;
  height: 82vh;
  border-radius: 10px;
}