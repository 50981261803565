.logotitle {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
 // align-items: center;
  // cursor: pointer;
  .show-img {
    display: block;
  }
  .hide-img {
    display: none;
  }
  .logo {
    color: #000;
    // width: 32px;
  }

  img{
    margin-top: -5px;
    margin-left: 0 !important;
    height: 29px !important;
  }
  
  .title {
    color: #000;
    margin-left: 12px;
    word-break: break-word;
    line-height: 24px;
    font-weight: 500;
    font-size: 20px;
  }
  .cursorDisplay{
    cursor: pointer;
  }
  .img-icon-wrapper{
   // height: 24px ;
    display: inline-flex;
    justify-content: center;
    //margin-top: 2px;
  }
  .img-icon-wrapper-favIcon{
      height: 20px ;
     display: inline-flex;
     justify-content: center;
     margin-top: 2px ;
    // margin-top: 2px; 
  }
}
