.chat_position {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border-radius: 25px;

  .search-container {
    padding: 10px;
    border-radius: 25px;
  }

  .send_icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.input-lable {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}

.input-container::placeholder {
}

.input-container {
  background: #f7f7f7;
  border-radius: 6px;
  color: #899197;
  width: 100%;
  border: none;
  padding: 14px;
  font-weight: 500;
  font-family: "";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.input-container:focus-visible {
  outline: none;
  box-shadow: none;
}

.input_image {
  height: 17px;
  width: 17px;
  margin-right: 12px;
}

.inpu_icon_cont {
  background: #f7f7f7;
  border-radius: 6px;
  margin-bottom: 30px;
  align-items: center;
}
